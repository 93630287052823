.container {
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
}

.sigContainer {
  width: 80%;
  height: 60%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.buttons {
  width: 15%;
  height: 80px;
  margin: 10px;
  font-size: 150%;
}

.buttonSave {
  width: 45%;
  height: 80px;
  margin: 10px;
  font-size: 150%;
}

.pbuttons {
  text-align: center;
}
